import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-deal-standard-quote',
  templateUrl: './view-deal-standard-quote.component.html',
  styleUrls: ['./view-deal-standard-quote.component.css'],
})
export class ViewDealStandardQuoteComponent implements OnInit {
  showSection: number = 1;
  @Input() payments: any[];
  @Input() specialPayments: any[];
  @Input() grace: any[];
  @Input() id: any;
  @Input() idDeal: any;

  constructor() {}

  ngOnInit() {}

  selectSection(index: number) {
    this.showSection = index;
  }
}
