import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FinancialProductEnum } from '../../model/standard-quote.model';
import { AuthService } from '@app/common/auth.service';
import { Util } from '@app/core/util/Util';

@Component({
  selector: 'app-column-deal-standard',
  templateUrl: './column-deal-standard.component.html',
  styleUrls: ['./column-deal-standard.component.css'],
})
export class ColumnDealStandardComponent implements OnInit {
  @Input() deals: any[];
  @Input() creationUser: any;

  @Output() isSelected: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() duplicate: EventEmitter<any> = new EventEmitter();

  constructor(public authService: AuthService) {}

  ngOnInit() {}

  getFinancialProduct(financialProductType: FinancialProductEnum) {
    if (financialProductType == FinancialProductEnum.directCredit) {
      return 'Crédito directo';
    } else if (financialProductType == FinancialProductEnum.financialLeasing) {
      return 'Arrendamiento financiero';
    } else if (financialProductType == FinancialProductEnum.pureLease) {
      return 'Arrendamiento puro';
    } else {
      return '';
    }
  }

  showInsuranceVehicle(deal: any) {
    return Util.showInsuranceVehicle(deal);
  }
}
