<app-notifications></app-notifications>
<nav class="menu">
  <div class="container">
    <div class="main-menu">
      <div id="js--menu-toggle" [ngClass]="isMenuOpen ? 'menu-toggle menu-toggle--open' : 'menu-toggle'" (click)="openMenu()">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <!-- Breadcrum -->

      <!-- Link al área principal -->
      <a class="menu-root-link" [href]="currentLink">{{ currentArea }}</a>

      <!-- Link al segundo nivel (si existe) -->
      <ng-container *ngIf="breadcrumb != ''">
        <a class="menu-page-link" [href]="breadcrumbPath">{{
          breadcrumb
          }}</a></ng-container>
      <ng-container *ngIf="breadcrumbChildren != ''">
        <span class="menu-separator"></span><!-- Símbolo ">" para separar los items del menú -->

        <!-- Nombre de la página actul (no es link, puede estar en segundo o tercer nivel) -->
        <span class="menu-page-actual">{{
          breadcrumbChildren
          }}</span></ng-container>
    </div>
  </div>
  <!-- </div> -->

  <!-- Incluir clase "menu-wrap--open" para mostrar el menú-->
  <!-- <div class="menu-wrap menu-wrap--open"> -->
  <div [ngClass]="isMenuOpen ? 'menu-wrap menu-wrap--open' : 'menu-wrap'">
    <div class="menu-wrap__container">

      <!-- En caso que el usuario no tenga un perfil asigando, omitir ul.menu_list -->
      <ul class="menu__list" *ngIf="menus.length > 0">
        <li class="menu__list-item menu__list-item--start">
          <a class="menu__link" href="/home"> {{ "Init" | translate }} </a>
        </li>
        <!-- menu__list-item--has-child agregar en el for solo si tiene children-->
        <li *ngFor="let menu of menusSubs" [ngClass]="{
            'menu__list-item menu__list-item--has-child':
              menu.children && menu.children.length > 0,
            'menu__list-item': !isLinkOpen,
            open:
              openItemId === menu.id &&
              (!isLinkOpen || (menu.children && menu.children.length > 0))
          }" (click)="openLink(menu.id)" [id]="menu.id">
          <div class="menu__item-wrap">
            <a *ngIf="menu.menuCode" (click)="generateLink(menu.menuCode)" class="menu__link">{{ menu.description }}</a>
            <span *ngIf="menu.children && menu.children.length > 0" class="menu__toggle-sub menu__toggle-sub--1st-lvl"></span>
          </div>
          <ng-container *ngFor="let child of menu.children">
            <ul class="menu__sub">
              <li class="menu__sub-item">
                <a (click)="generateLink(child.menuCode)" class="menu__sub-link">
                  {{ child.description }}
                </a>
              </li>
            </ul>
          </ng-container>
        </li>
      </ul>

      <!-- En caso que el usuario no tenga un perfil asigando, mostrar el p.note -->
      <p class="note" *ngIf="menus.length == 0">Por favor comuníquese con el administrador del sitio para acceder a las opciones del menú.</p>

    </div>
  </div>
</nav>