import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StandardQuoteService } from '../../standard-quote-service.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  quoteDto,
  quoteRequestDto,
  grace,
  specialPayments,
  modelTypeEnum,
  models,
  FinancialProductEnum,
} from '../../model/standard-quote.model';
import { BaseComponent } from '@app/components/base/base.component';
import { MenusServices } from '@app/services/menus.service';
import { NotificationService } from '@app/services/notifications.service';
import { FinancialProductComponent } from '../financial-product/financial-product.component';
import { environment } from '@env/environment.local';
import { BodyCalculate } from '../../model/model-body.model';
import { Constants } from '@app/constants/Constants';
import { TranslateService } from "@ngx-translate/core";
import { of, catchError, concatMap } from 'rxjs';

@Component({
  selector: 'app-new-deal-standard-quote',
  templateUrl: './new-deal-standard-quote.component.html',
  styleUrls: ['./new-deal-standard-quote.component.css'],
})
export class NewDealStandardQuoteComponent
  extends BaseComponent
  implements OnInit {
  idDeal: any;
  id: any;
  isLoading: boolean = false;
  form: FormGroup;
  dataService: quoteDto | null;
  showSection: number = 1;
  coverageResult: any;
  postalCode: string;
  activarBotonJson = environment.downloadJson;
  models: models | undefined = this.quoteService.formData?.model;
  financialProduct = FinancialProductEnum;
  constructor(
    route: ActivatedRoute,
    private translateService: TranslateService,
    menuService: MenusServices,
    notification: NotificationService,
    private router: Router,
    private quoteService: StandardQuoteService,
    private fb: FormBuilder
  ) {
    super(route, menuService, notification);
  }

  @ViewChild(FinancialProductComponent)
  financialProductComponent!: FinancialProductComponent;

  ngOnInit() {
    this.createForm();
    this.dataService = this.quoteService.formData;
    this.route?.paramMap.subscribe((params) => {
      this.id = params.get('id');
      this.idDeal = params.get('idDeal');
      if (this.idDeal) {
        // edicion
        this.getById();
      } else {
        if (this.quoteService.duplicateDeal) {
          let deal = {
            ...this.quoteService.duplicateDeal,
            financialSetup: {
              ...this.quoteService.duplicateDeal.financialSetup,
              financialCondition:
                this.quoteService.duplicateDeal.financialCondition,
              termId: null,
            },
          };
          this.form.patchValue(deal);

        }
      }
    });
  }

  getById() {
    if (!this.quoteService.formData) return;
    let deal: any = {};
    if (this.dataService && !this.quoteService.duplicateDeal) {
      deal = this.quoteService.formData?.standardQuoteDeals.find(
        (i) => i.id == this.idDeal
      );
      let result = this.quoteService.formData?.standardQuoteDeals.map(
        (deal) => {
          return {
            ...deal,
            financialSetup: {
              ...deal.financialSetup,
              openingFee: deal.standardFinancialSetup.openingFee,
              openingFeeTax: deal.standardFinancialSetup.openingFeeTax,
              downPaymentTax: deal.standardFinancialSetup.downPaymentTax,
              downPayment: deal.standardFinancialSetup.downPayment,
            },
            financialCondition: deal.financialCondition,
            standardQuoteConditions: deal.standardQuoteCondition,
            standardFinancialSetup: deal.standardFinancialSetup,
          };
        }
      );
      this.quoteService.formData.standardQuoteDeals = result;
    }
    this.form.patchValue(deal);
    this.postalCode = deal?.insurance?.postalCode;
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.idDeal, [Validators.required]],
      isSelected: [false, []],
      financialSetup: [, [Validators.required]],
      grace: [[], []],
      specialPayments: [[], []],
      payments: [[], [Validators.required]],
      result: [, []],
      insurance: [, []],
      financialCondition: [, [Validators.required]],
      comment: [, []],
      wholeSale: [, []]
    });
  }

  cancel() {
    if (this.id) {
      this.router.navigate([`standard-quote/edit-quote/${this.id}`]);
      this.quoteService.formData = null;
    } else {
      this.router.navigate([`quote/standard-quote/new-quote`]);
    }
  }

  setValueInService() {
    if (this.idDeal && this.dataService) {
      const index = this.dataService.standardQuoteDeals.findIndex(
        (i) => i.id == this.idDeal
      );
      this.dataService.standardQuoteDeals[index] = this.form.getRawValue();
    } else {
      this.dataService?.standardQuoteDeals.push(this.form.getRawValue());
    }
    this.quoteService.formData = this.dataService;
  }

  calculate() {
    this.isLoading = true;
    const models: any  = this.quoteService.formData?.model;
    const standardQuoteModel  = this.quoteService.formData?.standardQuoteModel ;
    const customer: any  = this.quoteService.formData?.customer;
    const payments = this.form.controls['payments'].value;
    this.form.controls['result'].setValue(null);

    this.quoteService.calculateQuote(this.form.getRawValue(), models, customer, standardQuoteModel).subscribe({
      next: (response) => {
        this.isLoading = false;
        //timeout
        if (!response.status && response.error === Constants.QUOTE_ERROR_PROCOTIZA_TIME_OUT_INTEGRATION) {
          this.showNotificationError(
            this.translateService.instant(Constants.QUOTE_ERROR_PROCOTIZA_TIME_OUT_INTEGRATION)
          );
          return;
        } else if (!response.status && response.error && response.error.Detalle) {
          this.showNotificationError(
            response.error.Detalle
          );
        } else if (!response.status) {
          this.showNotificationError(
            'Ha ocurrido un error al momento de realizar el calculo. Por favor, intente nuevamente o solicite soporte.'
          );
          return
        }
        if (payments.length > 0 && this.idDeal != null) {
          this.deletePayments(response.data[0]);
        } else {
          this.assignResult(response.data[0]);
        }
      },
      error: (err) => {
        this.showNotificationError(
          'Ha ocurrido un error al momento de calcular.'
        );
        this.isLoading = false;
      },
    });
  }

  save(redirection?: boolean) {
    const id = this.quoteService.formData?.id;
    if (id) {
      this.update(id, redirection);
    } else {
      this.saveDraft();
    }
  }

  updateSpecialPayments(specialPayments: specialPayments[]) {
    this.form.controls['specialPayments'].setValue(specialPayments);
    this.calculate();
  }

  updateGrace(grace: grace[]) {
    this.form.controls['grace'].setValue(grace);
    this.calculate();
  }

  update(id: number, redirection?: boolean) {
    this.isLoading = true;
    if (redirection) {
      this.setValueInService();
    }
    this.quoteService.updateQuote(id).subscribe({
      next: (response) => {
        // if (response.status == false) {
        //   this.showNotificationError(
        //     'Ha ocurrido un error al momento de guardar.'
        //   );
        // }
        this.isLoading = false;
        this.setResponseOnForm(response.data);
        if (redirection) {
          this.cancel();
          this.quoteService.formData = null;
        }
      },
      error: (error) => {
        this.showNotificationError(
          'Ha ocurrido un error al momento de guardar.'
        );
        this.isLoading = false;
      },
    });
  }

  saveDraft() {
    this.isLoading = true;
    this.quoteService.saveDraft().subscribe({
      next: (response) => {
        // if (response.status == false) {
        // this.showNotificationError(
        //   'Ha ocurrido un error al momento de guardar.'
        // );
        // }
        this.isLoading = false;

        this.setResponseOnForm(response.data);
      },
      error: (error) => {
        this.showNotificationError(
          'Ha ocurrido un error al momento de guardar.'
        );
        this.isLoading = false;
      },
    });
  }

  setResponseOnForm(response: quoteRequestDto) {
    if (!this.dataService) return;
    this.id = response.id;
    this.dataService.id = response.id;
    this.dataService.quantity = response.quantity;
    this.dataService.creationDate = response.creationDate;
    this.dataService.updatedDate = response.updatedDate;
    this.dataService.creationUser = response.creationUser;
    this.dataService.status = response.status;
    this.dataService.dealAmount = response.dealAmount;
    this.dataService.customer = response.customer;
    response.model.priceType = response.standardQuoteModel.priceType;
    this.dataService.model = response.model;
    this.dataService.standardQuoteModel = response.standardQuoteModel;
    this.dataService.accessories = response.accessories;
    this.dataService.standardQuoteDeals = this.setValueDownpaymentAndOpeningfee(
      response.standardQuoteDeals
    );
    if (this.idDeal) {
      const deal = this.dataService.standardQuoteDeals.filter(
        (i) => i.id == this.idDeal
      )[0];
      this.form.patchValue(this.setValue(deal));
    } else {
      let lengthDeals = this.dataService.standardQuoteDeals.length - 1;
      this.form.patchValue(
        this.setValue(this.dataService.standardQuoteDeals[lengthDeals])
      );
    }
    this.idDeal = this.form.controls['id'].value;
    this.quoteService.formData = this.dataService;
  }

  selectSection(index: number) {
    this.showSection = index;
  }

  setCondition(condition: any) {
    this.form.controls['financialCondition'].setValue(condition);
  }

  deletePayments(result: quoteDto) {
    const id = this.form.controls['id'].value;
    this.isLoading = true;
    this.quoteService.deletePayments(this.id, id).subscribe({
      next: (response) => {
        this.assignResult(result);
      },
      error: (err) => {
        this.isLoading = false;
      },
    });
  }

  setValue(deal: any) {
    let newValues = {
      id: deal.id,
      isSelected: deal.isSelected,
      grace: deal.grace,
      specialPayments: deal.specialPayments,
      payments: deal.payments,
      result: deal.result,
      insurance: deal.insurance,
      comment: deal.comment,
    };
    return newValues;
  }

  assignResult(result: any) {
    this.form.controls['result'].setValue({
      term: result.term,
      unitPrice: result.unitPrice,
      unitPriceNoTax: result.unitPriceNoTax,
      annualPercentageRate: result.annualPercentageRate,
      downpaymentAmount: result.downpaymentAmount,
      openingfeeAmount: result.openingfeeAmount,
      guaranteeDepositAmount: result.guaranteeDepositAmount,
      insuranceAmount: result.insuranceAmount,
      additionalServicesAmount: result.additionalServicesAmount,
      confirmationExpensesAmount: result.confirmationExpensesAmount,
      initialPayment: result.initialPayment,
      financialAmount: result.financialAmount,
      monthlyPayment: result.monthlyPayment,
      annualTotalCost: result.annualTotalCost,
      purchaseOptionAmount: result.purchaseOptionAmount,
      residualValue: result.residualValue,
    });
    this.form.controls['payments'].setValue(result.payments);
    this.setValueInService();
    this.isLoading = false;
    this.save();
  }

  deleteAllPayments() {
    const insurance = this.form.controls['insurance'].value;
    this.isLoading = true;

    let deleteInsurance$ = of(null);
    if (insurance && insurance.id) {
      deleteInsurance$ = this.quoteService.deleteInsurance(this.id, this.idDeal, insurance.id).pipe(
        catchError(error => {
          this.showNotificationError('Ha ocurrido un error en el borrado de seguro. Vuelva a intentarlo.');
          return of(null);
        })
      );
    } else {
      this.resetInsurance('a');
    }

    deleteInsurance$.pipe(
      concatMap(() => this.quoteService.deleteAllGracePeriod(this.id, this.idDeal).pipe(
        catchError(error => {
          this.showNotificationError('Ha ocurrido un error en el borrado del período de gracia. Vuelva a intentarlo.');
          return of(null);
        })
      )),
      concatMap(() => this.quoteService.deleteAllSpecialPayments(this.id, this.idDeal).pipe(
        catchError(error => {
          this.showNotificationError('Ha ocurrido un error en el borrado de pagos especiales. Vuelva a intentarlo.');
          return of(null);
        })
      ))
    ).subscribe({
      next: () => {
        this.isLoading = false;
        this.form.controls['grace'].setValue([]);
        this.form.controls['specialPayments'].setValue([]);
        this.form.controls['insurance'].setValue(null);
        this.form.updateValueAndValidity();
        this.showNotificationSuccess(
          'Se eliminaron correctamente los períodos de gracia, pagos especiales y seguros asociados a la propuesta.'
        );
      },
      error: () => {
        this.isLoading = false;
        this.showNotificationError('Ha ocurrido un error. Vuelva a intentarlo.');
      }
    });
  }
  deleteAllGracePeriod(hasSpecialPayments: boolean) {
    this.isLoading = true;
    this.quoteService.deleteAllGracePeriod(this.id, this.idDeal).subscribe({
      next: (response) => {
        if (!hasSpecialPayments) {
          this.isLoading = false;
          this.form.controls['grace'].setValue([]);
          this.form.updateValueAndValidity();
          this.showNotificationSuccess(
            'Períodos de gracia han sido eliminados correctamente.'
          );
        } else {
          this.deleteAllSpecialPayments(true);
        }
      },
      error: (error) => {
        this.showNotificationError(
          'Ha ocurrido un error. Vuelva a intentarlo.'
        );
        this.isLoading = false;
      },
    });
  }

  deleteAllSpecialPayments(hasPeriodGrace?: boolean) {
    this.isLoading = true;
    this.quoteService.deleteAllSpecialPayments(this.id, this.idDeal).subscribe({
      next: (response) => {
        if (!hasPeriodGrace) {
          this.showNotificationSuccess(
            'Pagos especiales han sido eliminados correctamente.'
          );
          this.form.controls['specialPayments'].setValue([]);
        } else {
          this.form.controls['grace'].setValue([]);
          this.form.controls['specialPayments'].setValue([]);
          this.showNotificationSuccess(
            'Pagos especiales y períodos de gracia han sido eliminados correctamente.'
          );
        }
        this.isLoading = false;
      },
      error: (error) => {
        this.showNotificationError(
          'Ha ocurrido un error. Vuelva a intentarlo.'
        );
        this.isLoading = false;
      },
    });
  }

  setValueDownpaymentAndOpeningfee(deals: any) {
    let newDeals = deals.map((i: any) => {
      return {
        ...i,
        financialSetup: {
          ...i.financialSetup,
          downPayment: i.downPayment,
          downPaymentTax: i.downPaymentTax,
          downPaymentTaxType: i.downPaymentTaxType,
          openingFee: i.openingFee,
          openingFeeTax: i.openingFeeTax,
          openingFeeTaxType: i.openingFeeTaxType,
          termId: i.financialCondition.id,
        },
      };
    });
    return newDeals;
  }

  resetInsurance(e: any) {
    this.form.controls['insurance'].setValue(null);
  }

  status() {
    const form = this.form;
    console.log(form.valid, 'es valido?');
    console.log(form.value, 'valores');
    console.log(form);
    for (const controlName of Object.keys(form.controls)) {
      const control = form.get(controlName);

      if (control && control.invalid) {
        console.log(`Campo inválido: ${controlName}`);
      }
    }
    console.log(this.form.controls['financialSetup'].valid, 'model');
    console.log(this.form.controls['payments'].valid, 'customer');
    console.log(this.form.controls['financialCondition'].valid, 'quantity');
  }

  handleDownloadJson() {
    let model = new BodyCalculate(
      this.form.getRawValue(),
      this.quoteService.formData,
      this.quoteService.formData?.model || null,
      this.quoteService.formData?.customer || null,
      null,
      this.quoteService.formData?.standardQuoteModel
    );

    this.quoteService.calculateStandardQuoteOpenJson(model).subscribe(
      (response) => {
        if (response.status === true) {
          const jsonStr = JSON.stringify(response.data, null, 2);

          const cotizacionFileName = this.idDeal
            ? `CotizacionE(${this.idDeal}).json`
            : 'CotizacionE.json';

          const blob = new Blob([jsonStr], { type: 'application/json' });

          const url = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;
          a.download = cotizacionFileName;
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
        }
      },
      (error) => {
        console.error('Error al descargar el JSON', error);
      }
    );
  }
  triggerDownloadJson() {
    this.financialProductComponent.emitFormDataForDownload();
  }

  canAddInsurance() {
    if (
      this.form.controls['financialSetup'].value &&
      (this.models?.modelType === modelTypeEnum.autobuses ||
        this.models?.modelType === modelTypeEnum.tractos) &&
        this.form.controls['financialSetup'].value.financialProduct == this.financialProduct.financialLeasing
    ) {
      return false;
    }
    return true;
  }
}
