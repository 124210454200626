import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenusServices } from '@app/services/menus.service';
import { NotificationService } from '@app/services/notifications.service';
import {
  modelTypeEnum,
  models,
  priceTypeEnum,
} from '../model/standard-quote.model';
import { StandardQuoteService } from '../standard-quote-service.service';
import { BaseComponent } from '@app/components/base/base.component';
import { AuthService } from '@app/common/auth.service';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModelsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ModelsComponent),
      multi: true,
    },
  ],
})
export class ModelsComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  form: FormGroup;
  allModels: models[] = [];
  modelOptions: any[] = [];
  _priceType: any[] = this.quoteService.priceType;
  isLoading: boolean = false;
  _model: any;
  @Input() set idQuote(value: number){
    this._idQuote = value;
      if(!this._idQuote){
        this.change = 1;
      }
  }

  @Input() set quantity(value: number){
    if(this.form){
      this.form.controls['quantity'].setValue(value);
    }
  }
  @Input() creationUser: any;
  @Input() set models(value: any[]) {
    this.allModels = value;
    // check model is not null and standardQuoteModel is not null and then search model from all models and then update price
    // from standardQuoteModel to price instead from all models
    if (this.form.controls['classification'].value) {
      this.filterYears();
      this.filterModels();
      this.change = 1;
    }
  }

  @Output() accessories: EventEmitter<any> = new EventEmitter<any>();
  @Output() _quantity: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeQuote: EventEmitter<any> = new EventEmitter<any>();
  @Output() requestOpenModal: EventEmitter<void> = new EventEmitter<void>();

  years: any[] = [];
  change: number = 0;
  changeRequestOpenModal: number = 0;
  _idQuote: number;
  constructor(
    route: ActivatedRoute,
    menu: MenusServices,
    private fb: FormBuilder,
    notifications: NotificationService,
    public quoteService: StandardQuoteService,
    public authService: AuthService
  ) {
    super(route, menu, notifications);
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['creationUser']) {
      if (this.creationUser) {
        if (this.creationUser != this.authService.currentUserIds) {
          this.form.controls['modelType'].disable();
          this.form.controls['modelCode'].disable();
          this.form.controls['classification'].disable();
          this.form.controls['year'].disable();
          this.form.controls['priceType'].disable();
          this.form.controls['price'].disable();
          this.form.controls['currency'].disable();
          this.form.controls['quantity'].disable();
        }
      }
    }
  }



  ngOnInit() {
  }

  createForm() {
    this.form = this.fb.group({
      id: [, [Validators.required]],
      modelType: [, [Validators.required]],
      modelCode: [, [Validators.required]],
      classification: [, [Validators.required]],
      year: [, [Validators.required]],
      priceType: [priceTypeEnum.priceNormal, [Validators.required]],
      price: [, [Validators.required]],
      currency: [, [Validators.required]],
      quantity: [, [Validators.required]],
      subBrand: [, [Validators.required]],
      brand: [, [Validators.required]],
      name: [, []],
    });

    this.form.valueChanges.subscribe((updatedModel) => {
      this.onChange(updatedModel);
      this.onTouched();
    });
  }

  onChangeType() {
    if(this.change == 1 && this.changeRequestOpenModal == 0){
      this.form.controls['classification'].setValue(null);
      this.form.controls['year'].setValue(null);
      this.form.controls['name'].setValue(null);
      this.form.controls['price'].setValue(null);
      this.form.controls['currency'].setValue(null);
      this.form.controls['quantity'].setValue(null);
      this.modelOptions = [];
    }
    this.filterModels();
  }

  onChangeYears() {
    this.filterYears();
    if(this.change == 1 && this.changeRequestOpenModal == 0){
      this.onInputFocus()
      this.form.controls['year'].setValue(null);
      this.form.controls['name'].setValue(null);
      this.form.controls['price'].setValue(null);
      this.form.controls['currency'].setValue(null);
      this.form.controls['quantity'].setValue(null);
      this.modelOptions = [];
    }
    this.filterModels()
  }

  filterByYear(classification: boolean, model: models){
    const currentYear = new Date().getFullYear();
    // new
    if (classification) {
      return model.year <= (currentYear + 1) && model.year >= (currentYear - 2)
    } else{
      return model.year <= (currentYear ) && model.year >= (currentYear - 13)
    }
  }

  filterYears(){
    const modelType = this.form.controls['modelType'].value;
    const classification = this.form.controls['classification'].value;
    this.years = [];

    if (!modelType || classification === '') return;
    let currentYear = new Date().getFullYear();
    let years = this.allModels.filter(
      (i) => i.modelType == modelType && i.classification == classification
      && this.filterByYear(classification, i)
    );

    let yearsSelected = [...new Set(years.map((i) => i.year))];
    //get current year and add to the list


    this.years = yearsSelected
      .map((i) => {
        return { value: i, description: i };
      })
      .sort((a, b) => a.value - b.value);
  }

  onChangeModels() {
    const year = this.form.controls['year'].value;
    if(!year) return;
    this.modelOptions = this.allModels
      .filter(
        (i) =>
          i.year == year
      )
      .map((i) => {
        return { label: i.name, id: i.id, value: i.modelCode, modelType: i.modelType, classification: i.classification, year: i.year};
      });
      if(this.change == 1 && this.changeRequestOpenModal == 0){
        this.onInputFocus()
        this.form.controls['name'].setValue(null);
        this.form.controls['price'].setValue(null);
        this.form.controls['currency'].setValue(null);
        this.form.controls['quantity'].setValue(null);
      }
  }

  filterModels(){
    const modelType = this.form.controls['modelType'].value;
    const classification = this.form.controls['classification'].value;
    const year = this.form.controls['year'].value;
    if ((!modelType || classification === '' ) && !year) return;
    //llamar a models
    this.isLoading = true;
    this.quoteService.getModelsByFilter(
      {
        modelType: modelType,
        classification: classification
      }
    ).subscribe({
      next: (response) => {
        this.allModels = response.data;
        this.modelOptions = this.allModels.map((i) => {
          return { label: i.name, id: i.id, value: i.modelCode, modelType: i.modelType, classification: i.classification, year: i.year};
        })
        this.isLoading = false;
        this.filterYears()
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  selectedModel(modelCode: number) {

    if(this.change == 1 && this.changeRequestOpenModal == 0){
      this.onInputFocus();
    }else if(this.changeRequestOpenModal == 1){
      this.changeRequestOpenModal = 0;
    }

    let product = {};
    const productSelected = this.allModels.find((i) => i.id == modelCode);
    if (modelCode == null) {
      product = {
        modelCode: null,
        currency: null,
        price: null,
        brand: null,
        subBrand: null,
        name: null,
      };
      this.form.patchValue(product);
    } else {
      product = {
        id: productSelected?.id,
        modelCode: productSelected?.modelCode,
        currency: productSelected?.currency,
        price: productSelected?.price,
        brand: {
          id: productSelected?.brand.id,
        },
        subBrand: {
          id: productSelected?.subBrand.id,
        },
        name: productSelected?.name,
      };
      this.form.patchValue(product);
    }
  }

  emitQuantity() {
    const quantity = this.form.controls['quantity'].value;
    this._quantity.emit(quantity);
  }

  calculateTotalSale() {
    const price = this.form.controls['price'].value;
    const quantity = this.form.controls['quantity'].value;
    const totalSale = price * quantity;
    return totalSale;
  }


  //#region CVA
  writeValue(value: any): void {
    if (value) {
      this._model = value;
      this.form.patchValue(value, { emitEvent: false });
      if(this.change == 1 && this.changeRequestOpenModal == 1){
        this.filterYears();
        this.filterModels();
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private onChange = (_: any) => {};
  private onTouched = () => {};

  //#endregion

  //#region Validator
  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    return this.form.valid ? null : { invalidFormModel: true };
  }


  onInputFocus() {
    this.requestOpenModal.emit();
    this.changeRequestOpenModal = 1;
  }

  //#endregion
}
