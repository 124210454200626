<app-preloader *ngIf="isLoading"></app-preloader>

<div class="module" id="financialProduct">
  <div class="title">
    <h3>Producto Financiero</h3>
  </div>
  <!-- <button (click)="status()">Status</button> -->
  <form [formGroup]="formFinance" class="formFinance">
    <div class="wrapperG3">
      <div class="selectWrapper">
        <label for="">Producto financiero<span class="required">*</span></label>
        <div class="customSelect">
          <select type="text" formControlName="financialProduct" (ngModelChange)="onChangeFinancialProduct()">
            <option *ngFor="
                let financialProduct of quoteService.financialProductsList
              " [value]="financialProduct.value">
              {{ financialProduct.name }}
            </option>
          </select>
        </div>
      </div>
      <div *ngIf="_financialPackageOptions.length > 0; else withoutPackages" class="selectWrapper">
        <label for="">Paquete<span class="required">*</span></label>
        <div class="customSelect">
          <select type="text" formControlName="id" (ngModelChange)="onChangePackage()">
            <option [value]="null" disabled selected>Seleccione un paquete</option>
            <option *ngFor="let package of _financialPackageOptions" [value]="package.id">
              {{ package.financialSetupName }}
            </option>
          </select>
        </div>
      </div>
      <ng-template #withoutPackages>
        <label class="msg-select">Sin paquetes financieros</label>
      </ng-template>
      <div *ngIf="_financialPackageOptions.length > 0" class="plan">
        <div class="selectWrapper">
          <label>Plan (descripción del paquete)</label>
          <span>{{ packageDescription }}</span>
        </div>
      </div>
    </div>

    <div *ngIf=" _financialPackageOptions.length > 0 && paymentTerms.length > 0; else withoutPaymentTerms "
      class="wrapperG3">
      <!-- <div class="plazoYTasa"> -->
      <div class="selectWrapper">
        @if (formFinance.controls['id'].value){
        <label for="">Plazo<span class="required">*</span></label>
        <div class="customSelect">
          <select type="text" formControlName="termId" (ngModelChange)="onChangeTerm()">
            <option [value]="null" disabled selected>
              Seleccione un plazo
            </option>
            <option *ngFor="let terms of paymentTerms" [value]="terms.id">
              {{ terms.term }} MESES
            </option>
          </select>
        </div>
        }
      </div>
      <div class="inputWrapper">
        <div class="wrapperSX">
          @if (formFinance.controls['termId'].value){
          <label for="">Tasa</label>
          <input type="text" class="size1 gapRight" formControlName="nominalRate"
            [value]="formFinance.controls['nominalRate'].value" [disabled]="true" />
          <span class="type">%</span>
          }
        </div>
      </div>
      <!-- </div> -->
      <!-- Tipo de pago -->
      <div class="selectWrapper tipoDePago">
        <label for="">Tipo de pago</label>
        <span> {{ quoteService.getPaymentType( formFinance.controls["paymentType"].value) }} </span>
      </div>
    </div>
    <ng-template #withoutPaymentTerms>
      <label *ngIf="_financialPackageOptions.length > 0 && paymentTerms.length == 0" class="msg-select">Sin plazos
        disponibles. Por favor, seleccione otro paquete
        financiero.</label>
    </ng-template>

    <div *ngIf="_financialPackageOptions.length > 0 && paymentTerms.length > 0" class="wrapperG3">

      @if (showDownPayment() && formFinance.controls['termId'].value ) {
      <!-- Enganche -->
      <div class="selectWrapper enganche">
        <label>Enganche <span class="required">*</span></label>
        <div class="wrapperEnganche">
          <!-- Es porcentaje -->
          <div class="inputWrapper porcentaje" *ngIf="
              formFinance.controls['downPaymentTaxType'].value !=
              taxType.fixedAmount
            ">
            <div *ngIf="formFinance.controls['termId'].value">
              <input class="size1 gapRight" type="number" formControlName="downPaymentTax"
                (blur)="calculateDownPayment('downPayment', 'percentaje')"
                (keypress)="preventInvalidCharacters($event)" />
            </div>

            <span class="type gapRight" *ngIf="
                formFinance.controls['downPaymentTaxType'].value !=
                taxType.fixedAmount
              ">%</span>
            <div class="rango">
              <span class="note">Min {{ minDownPaymentPercentage }}%<br />Max
                {{ maxDownPaymentPercentage }}%</span>
            </div>
          </div>
          <ng-container *ngIf="
              formFinance.controls['downPaymentTaxType'].value !=
              taxType.fixedAmount
            ">
            <div class="inputWrapper monto gapRight">
              <input type="number" id="downPayment" class="amount" style="display: none" formControlName="downPayment"
                (blur)="calculateDownPayment('downPayment', 'fixed')" (keypress)="preventInvalidCharacters($event)" />
              <input type="text" id="hidden-downPayment" class="amount" (focus)="showAmountInput('downPayment')"
                style="display: flex" [value]="
                  formFinance.controls['downPayment'].value | currency : '' : ''
                " />
            </div>
          </ng-container>
          <div class="moneda">{{ models?.currency }}</div>
          <!-- Es monto fijo -->
          <ng-container *ngIf="
              formFinance.controls['downPaymentTaxType'].value ==
              taxType.fixedAmount
            ">
            <div class="inputWrapper monto">
              <input type="number" id="downPayment" class="amount" style="display: none" formControlName="downPayment"
                (blur)="calculateDownPayment('downPayment')" (keypress)="preventInvalidCharacters($event)" />
              <input type="text" id="hidden-downPayment" class="amount" (focus)="showAmountInput('downPayment')"
                style="display: flex" [value]="
                  formFinance.controls['downPayment'].value | currency : '' : ''
                " />
            </div>
          </ng-container>
        </div>
      </div>
      }


      <!-- Comisión por apertura -->
      <div class="selectWrapper">
        @if (formFinance.controls['termId'].value){
        <label>Comisión por apertura <span class="required">*</span></label>
        <div class="wrapperComision">
          <!-- <div class="customSelect">
            <select [disabled]="true">
              <option selected>
                {{
                  quoteService.getTaxType(
                    formFinance.controls["openingFeeType"].value
                  )
                }}
              </option>
            </select>
          </div> -->

          <!-- Es porcentaje -->
          <div class="inputWrapper porcentaje" *ngIf="
              formFinance.controls['openingFeeType'].value !=
              taxType.fixedAmount
            ">
            <span>
              {{ formFinance.controls["openingFeeTax"].value }}
            </span>
            <span class="type gapRight" *ngIf="
                formFinance.controls['openingFeeType'].value !=
                taxType.fixedAmount
              ">%</span>
          </div>
          <!-- <ng-container
            *ngIf="
              formFinance.controls['openingFeeType'].value !=
              taxType.fixedAmount
            "
          >
            <div class="inputWrapper monto gapRight">
              <input
                type="number"
                id="openingFee"
                class="amount"
                style="display: none"
                formControlName="openingFee"
                (blur)="calculateOpeningFee('openingFee', 'fixed')"
                (keypress)="preventInvalidCharacters($event)"
                class="amount"
              />
              <input
                type="text"
                id="hidden-openingFee"
                class="size2 amount gapRight"
                (focus)="showAmountInput('openingFee')"
                style="display: flex"
                [value]="
                  formFinance.controls['openingFee'].value | currency : '' : ''
                "
              />
            </div>
          </ng-container>

          <div class="moneda">{{ models?.currency }}</div> -->

          <!-- Es monto fijo -->
          <!-- <ng-container
            *ngIf="
              formFinance.controls['openingFeeType'].value ==
              taxType.fixedAmount
            "
          >
            <div class="inputWrapper monto">
              <input
                type="number"
                id="openingFee"
                class="amount"
                style="display: none"
                formControlName="openingFee"
                (blur)="calculateOpeningFee('openingFee')"
                class="amount"
                (keypress)="preventInvalidCharacters($event)"
              />
              <input
                type="text"
                id="hidden-openingFee"
                class="size2 amount gapRight"
                (focus)="showAmountInput('openingFee')"
                style="display: flex"
                [value]="
                  formFinance.controls['openingFee'].value | currency : '' : ''
                "
              />
            </div>
          </ng-container> -->
        </div>
        }
      </div>
    </div>
  </form>
</div>
<ng-template #modalTemplate>
  <div class="modal" style="max-width: 620px">
    <a class="close" (click)="cancelChanges()">Cerrar</a>
    <h1>Eliminar propuestas</h1>
    <p>
      Está editando datos sensibles, si continúa con los cambios la propuesta de
      seguro será eliminada.
    </p>
    <div class="modalActions index">
      <button (click)="cancelChanges()" class="secondary">Cancelar</button>
      <button (click)="deleteAllPayments()" class="primary">Eliminar</button>
    </div>
  </div>
</ng-template>
