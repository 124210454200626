<app-preloader *ngIf="isLoading"></app-preloader>

<div class="amortizations" id="amortizations">
  <div class="accordionWrapper">
    <div>
      <div class="mainWrapper">
        <!-- Si la tabla está vacía mostra el div.noData -->
        <div class="noData" *ngIf="payments.length <= 0">
          No hay información para mostrar
        </div>

        <!-- Tabla para amortizaciones -->
        <div class="amortizationsTable" *ngIf="payments.length > 0">
          <!-- La tabla completa está compuesta por 3 tablas -->
          <!-- 1. Tabla Período -->
          <div class="customTable amortizacionesPeriodos">
            <div class="titleRow"></div>

            <div class="header">
              <div class="cell numero">Nº</div>
              <div class="cell mes">Mes</div>
            </div>

            <!-- Si el período tiene aplicado un "Período de gracia" incluir en el div.row la clase periodoGracia -->
            <!-- Si el período tiene aplicado un "Pago especial" incluir en el div.row la clase pagoEspecial -->

            <ng-container *ngFor="let item of payments">
              <div
                class="row"
                [ngClass]="{
                  periodoGracia: hasPeriodGrace(item.period),
                  pagoEspecial: hasSpecialPayment(item.period)
                }"
              >
                <ng-container *ngIf="item.period != 0">
                  <div class="cell numero">
                    {{ item?.period }}
                  </div>
                  <div class="cell mes">
                    {{ formatDate(item?.paymentDate) }}
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>

          <!-- 2. Tabla Unidad -->
          <div class="customTable amortizacionesUnidadYTotal">
            <div class="titleRowWrapper">
              <div class="titleRow">Unidad</div>
              <div class="cell auxiliar"></div>
            </div>
            <div class="header">
              <div class="cell saldo">Saldo</div>
              <div class="cell intereses">Intereses</div>
              <div class="cell capital">Capital</div>
              <div class="cell iva">IVA</div>
              <div class="cell total">Total</div>
              <div class="cell auxiliar payment"></div>
            </div>

            <!-- Si el período tiene aplicado un "Período de gracia" o un "Pago especial" incluir en el div.row la clase edited -->
            <ng-container *ngFor="let item of payments">
              <div
                class="row"
                [ngClass]="{
                  periodoGracia: hasPeriodGrace(item.period),
                  pagoEspecial: hasSpecialPayment(item.period)
                }"
              >
                <ng-container *ngIf="item.period != 0">
                  <div class="cell saldo">
                    {{ item?.capitalBalance | currency : "" : "" }}
                  </div>
                  <div class="cell intereses">
                    {{ item?.interest | currency : "" : "" }}
                  </div>
                  <div class="cell capital">
                    {{ item?.capitalPayment | currency : "" : "" }}
                  </div>
                  <div class="cell iva">
                    {{ item?.interestTax | currency : "" : "" }}
                  </div>
                  <div class="cell total">
                    {{ item?.total | currency : "" : "" }}
                  </div>
                  <div class="cell auxiliar payment"></div>
                </ng-container>
              </div>
            </ng-container>
          </div>

          <!-- 3. Tabla Total -->
          <div class="customTable amortizacionesUnidadYTotal">
            <div class="titleRow">
              Total ({{ quantity != 0 ? quantity : "" }} unidades)
            </div>
            <div class="header">
              <div class="cell saldo">Saldo</div>
              <div class="cell intereses">Intereses</div>
              <div class="cell capital">Capital</div>
              <div class="cell iva">IVA</div>
              <div class="cell total">Total</div>
            </div>

            <!-- Si el período tiene aplicado un "Período de gracia" o un "Pago especial" incluir en el div.row la clase edited -->
            <ng-container *ngFor="let item of payments">
              <div
                class="row"
                [ngClass]="{
                  periodoGracia: hasPeriodGrace(item.period),
                  pagoEspecial: hasSpecialPayment(item.period)
                }"
              >
                <ng-container *ngIf="item.period != 0">
                  <div class="cell saldo">
                    {{ item.capitalBalance * quantity | currency : "" : "" }}
                  </div>
                  <div class="cell intereses">
                    {{ item.interest * quantity | currency : "" : "" }}
                  </div>
                  <div class="cell capital">
                    {{ item.capitalPayment * quantity | currency : "" : "" }}
                  </div>
                  <div class="cell iva">
                    {{ item.interestTax * quantity | currency : "" : "" }}
                  </div>
                  <div class="cell total">
                    {{ item.total * quantity | currency : "" : "" }}
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>

          <!-- 1. Tabla Período -->
          <!-- <ng-container>
            <div class="customTable amortizacionesAcciones">
              <div class="titleRow"></div>

              <div class="header">
                <div class="cell">Acciones</div>
              </div>
              <ng-container *ngFor="let item of payments">
                <div
                  class="row"
                  [ngClass]="{
                    periodoGracia: hasPeriodGrace(item.period),
                    pagoEspecial: hasSpecialPayment(item.period)
                  }"
                >
                  <!-- <ng-container *ngIf="item.period != 0">
                    <div class="cell">
                      <a
                        *ngIf="!isEdit"
                        class="edit"
                        helipopper="Editar pago"
                        tp="{ delay: [0, 0], duration: [null, 0] }"
                        (click)="openModal(item)"
                        >Editar períodos de gracia y pagos especiales</a
                      > -->

                  <!-- Incluir el indicador si es Período de Gracias -->
                  <!-- <span
                        *ngIf="hasPeriodGrace(item.period)"
                        class="type"
                        helipopper="Este período tiene asigando gracia"
                        tp="{ delay: [0, 0], duration: [null, 0] }"
                      >
                        PG
                      </span> -->

                  <!-- Incluir el indicador si es Pago Especial -->
                  <!-- <span
                        *ngIf="hasSpecialPayment(item.period)"
                        class="type"
                        helipopper="Este período tiene asigando un pago especial"
                        tp="{ delay: [0, 0], duration: [null, 0] }"
                      >
                        PE
                      </span> -->
                <!-- </div>
              </ng-container>
            </div>
          </ng-container> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #specialTemplate>
  <app-grace-special-payments
    [period]="period"
    [idQuote]="idQuote"
    [idDeal]="idDeal"
    (newPayment)="update($event)"
    (deletePayment)="delete($event)"
  ></app-grace-special-payments>
</ng-template> -->
