import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {quoteDto} from '../../model/standard-quote.model';
import {StandardQuoteService} from '../../standard-quote-service.service';
import {AuthService} from "../../../../../common/auth.service";

@Component({
  selector: 'app-financial-service-view',
  templateUrl: './financial-service-view.component.html',
  styleUrls: ['./financial-service-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialServiceViewComponent implements OnInit {
  private quoteService = inject(StandardQuoteService);
  financialService: quoteDto | null = this.quoteService.formData;
  dealerId: number;
  wholeSale: any;

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    const dealers = this.authService.currentDealers;
    if (this.financialService?.dealerId && this.financialService.wholeSaleId) {
      const dealer = dealers.find((i: any) => i.id == this.financialService?.dealerId);
      if (dealer) {
        const wholeSale = dealer?.wholeSales.find((i: any) => i.id === this.financialService?.wholeSaleId);
        if (wholeSale) {
          this.wholeSale = wholeSale.name
        }
        this.dealerId = dealer?.id;
      }
    } else {
      this.dealerId = this.financialService?.dealer ? this.financialService?.dealer.id : this.financialService?.dealerId;
      this.wholeSale = this.financialService?.wholeSale ? this.financialService?.wholeSale.name : this.financialService?.wholeSaleId;
    }
  }

  getCompanyName(name: string) {
    if (name == '') {
      // getCompanyName(
    } else if (name == '') {
    }
  }
}
