<header [ngClass]="{ loggedIn: authService.tokenLS }">
  <div id="brand">
    <div class="pageWidth">
      <a href="/home" class="logo">
        <img
          src="./assets/images/logo_dtfs.svg"
          alt="Daimler Truck Financial Services"
        />
      </a>

      <div class="wrapper">
        <!-- Mostrar div.acceso o div.profile según corresponda -->

        <!-- Links de acceso -->
        <div class="access" *ngIf="!authService.tokenLS">
          <label>Menú perfil</label>
          <a href="/">Ingresar</a>
          <a href="/register">Registrarse</a>
        </div>

        <!-- Menú perfil y nombre de usuario -->
        <div class="userAndProfile" *ngIf="authService.tokenLS">
          <a href="/notifications" class="notifications">
            <span class="unread">{{
              notificationService.countNotification
            }}</span>

            <img
              src="./assets/images/icons/envelope.svg"
              alt="Notificaciones"
            />
          </a>

          <div class="profile">
            <input
              type="checkbox"
              id="profile-toggle"
              [(ngModel)]="isComponentOpen"
              (click)="toggleComponent()"
            />
            <label for="profile-toggle">Menú perfil</label>
            <ul>
              <li
                *authPermission="
                  [];
                  role: [
                    rolesDealer.seller,
                    rolesDealer.administrative,
                    rolesDealer.manager,
                    rolesDealer.financial
                  ];
                  user: [userType.dealer]
                "
              >
                <a
                  href="/profile/my-profile
                "
                  >Mi Perfil</a
                >
              </li>
              <li><a href="/" (click)="authService.logout()">Salir</a></li>
            </ul>
          </div>
          <p class="username">¡Hola, {{ authService.currentName }}!</p>
          <!-- <div class="userAndDealer">
            <p class="username">{{ authService.currentName }}, Perfil</p>
            <p class="dealer">Distribuidor</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <app-menu *ngIf="authService.tokenLS"></app-menu>
</header>
