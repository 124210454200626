import { OnInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/common/auth.service';
import { Permission } from '@app/models/permissions.enum';
import { MenusServices } from '@app/services/menus.service';
import { environment } from '@env/environment.local';
import { ProcotizaService } from '@app/services/procotiza.service';
import { NotificationService } from '@app/services/notifications.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['menu.component.css'],
})
export class MenuComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    private menusService: MenusServices,
    private procotizaService: ProcotizaService,
    private notificationsService: NotificationService
  ) {}
  public currentRoute: any;
  currentArea: string = 'INICIO';
  currentLink: string = '/home';
  menus: any[] = [];
  menusItem: any[] = [];
  menusSubs: any[] = [];
  dealerSite = environment.DEALERS_SITE;
  breadcrumb: string = '';
  breadcrumbPath: string = '';
  breadcrumbChildren: string = '';
  openItemId: number;

  ngOnInit() {
    this.getMenu();
    this.subscribeToChangesInRoute();

    if (this.menus !== null) {
      this.menusSubs = this.menus.filter((menu) => menu.level == 0);
    }
  }

  getMenu(): any {
    this.menus = this.authService.currentMenus;
    this.menus.sort((a, b) => {
      if (a.description < b.description) {
        return -1;
      }
      if (a.description > b.description) {
        return 1;
      }
      return 0;
    });
  }

  subscribeToChangesInRoute() {
    this.menusService.breadcrumb.subscribe((data) => {
      if (!data) {
      }

      if (data) {
        this.currentLink = data.breadcrumb['path'];
        this.currentArea = data.breadcrumb['title'];
        this.breadcrumb = data.breadcrumb['parent'];
        this.breadcrumbPath = data.breadcrumb['path'];

        this.breadcrumbChildren = data.breadcrumb['children']
          ? data.breadcrumb['children']
          : '';
      }
    });
  }

  openMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  openSearch() {
    this.isSearch = !this.isSearch;
  }

  generateLink(menuCode: string) {
    let menu = this.menus.find((i)=> i.menuCode == menuCode);
    if (menuCode == Permission.usuarios1) {
      this.isMenuOpen = !this.isMenuOpen;
      this.router.navigate(['/users/admin-users']);
    } else if (menuCode == Permission.procotiza) {
      this.isMenuOpen = !this.isMenuOpen;
      this.redirectionProcotiza();
    } else if (menuCode == 'Cot_abierto1') {
      this.isMenuOpen = !this.isMenuOpen;
      this.router.navigate(['/quote/open-quote']);
    } else if (menuCode == 'contizadorStandard') {
      this.isMenuOpen = !this.isMenuOpen;
      this.router.navigate(['/quote/standard-quote']);
    } else if (menuCode == Permission.perfiles1) {
      this.isMenuOpen = !this.isMenuOpen;
      this.router.navigate(['profile/admin-profiles']);
    } else if (menuCode == Permission.circulares0) {
      this.isMenuOpen = !this.isMenuOpen;
      this.router.navigate(['/mailshot/admin-mailshot']);
    } else if (menuCode == Permission.directorio0) {
      this.isMenuOpen = !this.isMenuOpen;
      this.router.navigate(['/info/directory']);
    } else {
      if(menu && menu.children.length > 0) return;
      this.isMenuOpen = !this.isMenuOpen;
      const url =
        this.dealerSite +
        'token=' +
        this.authService.tokenLS +
        '&pagina=' +
        menuCode;
      window.location.href = url;
    }
  }
  openLink(itemId: number) {
    if (this.openItemId === itemId) {
      this.openItemId = 0; // Cerrar el elemento si ya está abierto
    } else {
      this.openItemId = itemId; // Abrir el elemento si está cerrado
    }
  }

  isLinkOpen: boolean = false;
  isMenuOpen: boolean = false;
  isSearch: boolean = false;

  redirectionProcotiza() {
    let procotiza = this.authService.currentProcotiza;

    if (procotiza === null) {
      this.notificationsService.showMessage(
        ['Ha ocurrido un error. Por favor contactarse con el administrador.'],
        'error'
      );
      setTimeout(() => {
        this.notificationsService.clearMessages();
      }, 3000);
      return;
    }

    let model = {
      userId: procotiza.userId,
      token: procotiza.refresh_token,
    };

    this.procotizaService.refreshToken(model).subscribe({
      next: (r) => {
        if (r.Url) {
          window.location.href = r.Url;
        } else {
          this.notificationsService.showMessage([r.Mensaje], 'error');
        }
      },
      error: (_error) => {
        this.notificationsService.showMessage(
          ['Ha ocurrido un error. Vuelva a intentarlo.'],
          'error'
        );
      },
    });
    setTimeout(() => {
      this.notificationsService.clearMessages();
    }, 3000);
  }
}
