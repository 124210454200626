<app-preloader *ngIf="isLoading"></app-preloader>
<div id="unit" class="module">
  <div class="title">
    <h3>Unidad</h3>
  </div>

  <form action="" [formGroup]="form">
    <div class="wrapperG3">
      <div class="selectWrapper">
        <label for="">Tipo de unidad<span class="required">*</span></label>
        <div class="customSelect">
          <select
            name=""
            id=""
            formControlName="modelType"
            (ngModelChange)="onChangeType()"
            (change)="onInputFocus()"
          >
            <option
              *ngFor="let product of quoteService.productType"
              [value]="product.productType"
            >
              {{ product.description }}
            </option>
          </select>
        </div>
      </div>
      <div class="selectWrapper">
        <label for="">Condición<span class="required">*</span></label>
        <div class="customSelect">
          <select
            name=""
            id=""
            formControlName="classification"
            (ngModelChange)="onChangeYears()"
          >
            <option [ngValue]="true">Nuevo</option>
            <option [ngValue]="false">Usado</option>
          </select>
        </div>
      </div>
      <div class="selectWrapper">
        <label for="">Año<span class="required">*</span></label>
        <div class="customSelect">
          <select
            name=""
            id=""
            formControlName="year"
            (ngModelChange)="onChangeModels()"
          >
            <option [value]="null" disabled selected>Seleccione un año</option>

            <option *ngFor="let year of years" [value]="year.value">
              {{ year.description }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="wrapperG2">
      <div class="inputWrapper" style="overflow: visible">
        <label for="">Modelo<span class="required">*</span></label>
        <app-input-search
          [selectedModel]="form.controls['name'].value"
          [options]="modelOptions"
          placeholder="Buscar modelo"
          (searchSelected)="selectedModel($event)"
          *ngIf="
            creationUser == null || creationUser == authService.currentUserIds
          "
        ></app-input-search>
        <input
          *ngIf="creationUser && creationUser != authService.currentUserIds"
          type="text"
          disabled
          value="{{ form.controls['name'].value }}"
        />
      </div>
      <div class="wrapperG4Custom">
        <div class="inputWrapper">
          <label for="">Precio unitario<span class="required">*</span></label>
          <input
            type="number"
            id="price"
            style="display: none"
            formControlName="price"
            (blur)="hideAmountInput('price')"
            (keypress)="preventInvalidCharacters($event)"
            (blur)="onInputFocus()"
          />
          <input
            type="text"
            id="hidden-price"
            (focus)="showAmountInput('price')"
            style="display: block"
            [value]="
              form.controls['price'].value | currency : '' : '' : '0.0-8'
            "
            [disabled]="
              creationUser && creationUser != authService.currentUserIds
            "
            (blur)="onInputFocus()"
          />
        </div>
        <div class="selectWrapper">
          <label for="">Moneda factura<span class="required">*</span></label>
          <div class="customSelect">
            <select
              name=""
              id=""
              formControlName="currency"
              (change)="onInputFocus()"
            >
              <option
                *ngFor="let currency of quoteService.currencies"
                [value]="currency.value"
              >
                {{ currency.type }}
              </option>
            </select>
          </div>
        </div>
        <div class="inputWrapper">
          <label for="">Cantidad<span class="required">*</span></label>
          <input
            type="number"
            id="quantity"
            formControlName="quantity"
            (ngModelChange)="emitQuantity()"
            (keypress)="preventInvalidCharacters($event)"
          />
        </div>
        <div class="inputWrapper">
          <label for="">Total venta</label>
          <span class="readOnly">{{ calculateTotalSale() | currency }}</span>
        </div>
      </div>
    </div>
  </form>
</div>
