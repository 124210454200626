<app-preloader *ngIf="isLoading"></app-preloader>
<div id="deals">
  <div class="titleWrapper">
    <h3>Propuestas</h3>
    <div class="switchView">
      <a
        class="list"
        [ngClass]="{ active: showRow == 1 }"
        tp="Ver propuestas en lista"
        (click)="changeDesign(1)"
        >Ver propuestas en lista</a
      >
      <a
        class="columns"
        [ngClass]="{ active: showRow == 2 }"
        tp="Ver propuestas en columnas"
        (click)="changeDesign(2)"
        >Ver propuestas en columnas</a
      >
    </div>
  </div>
  <button
    class="tertiary"
    (click)="addNewDeal()"
    [disabled]="!isValidForm"
    *ngIf="creationUser == null || creationUser == authService.currentUserIds"
  >
    <i class="icon"></i>
    Nueva Propuesta
  </button>
  <!-- -->
  <div class="noData" *ngIf="deals.length == 0">
    No hay información para mostrar
  </div>
  <div class="dealsList">
    <ng-container *ngIf="showRow == 1">
      <div *ngFor="let deal of deals; let i = index" class="deal">
        <app-row-deal-standard-quote
          [idQuote]="idQuote"
          [deal]="deal"
          [indexDeal]="i"
          (edit)="editDeal($event)"
          (duplicate)="duplicateDeal($event)"
          (delete)="openModalConfirmation($event)"
          [creationUser]="creationUser"
          [model]="model"
          (isSelected)="isSelected($event)"
        ></app-row-deal-standard-quote>
      </div>
    </ng-container>
    <ng-container *ngIf="showRow == 2">
      <app-column-deal-standard
        [deals]="paginatedDeals"
        (edit)="editDeal($event)"
        (duplicate)="duplicateDeal($event)"
        (delete)="openModalConfirmation($event)"
        [creationUser]="creationUser"
        (isSelected)="isSelected($event)"
      >
      </app-column-deal-standard>
      <ng-container *ngIf="deals.length > 8">
        <app-paginator
          [total]="deals.length"
          [label]="'Propuestas por página'"
          [pageSizeOptions]="[5, 8]"
          [pageSize]="pageSize"
          [pageIndex]="currentPageIndex"
          (changePage)="onPageChange($event)"
        ></app-paginator>
      </ng-container>
    </ng-container>
  </div>

  <button
    class="tertiary"
    (click)="addNewDeal()"
    [disabled]="!isValidForm"
    *ngIf="creationUser == null || creationUser == authService.currentUserIds"
  >
    <i class="icon"></i>
    Nueva Propuesta
  </button>
</div>
<ng-template #modalTemplate>
  <app-template-confirmation
    [title]="title"
    [subtitle]="subtitle"
    [btn1]="btn1"
    [btn2]="btn2"
    (closeModal)="closeModal()"
    (actionBtn2)="deleteDeal()"
  ></app-template-confirmation>
</ng-template>
