import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@app/common/auth.service';
import { FinancialProductEnum, models } from '../../model/standard-quote.model';
import {Util} from "../../../../../core/util/Util";

@Component({
  selector: 'app-row-deal-standard-quote',
  templateUrl: './row-deal-standard-quote.component.html',
  styleUrls: ['./row-deal-standard-quote.component.css'],
})
export class RowDealStandardQuoteComponent implements OnInit {
  @Input() indexDeal: number;
  @Input() deal: any;
  @Input() idQuote: any;
  @Input() creationUser: any;
  @Input() model: models;

  @Output() isSelected: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() duplicate: EventEmitter<any> = new EventEmitter();
  collapsed: boolean = true;

  constructor(public authService: AuthService) {}

  ngOnInit() {}

  viewDeal(indexDeal: any) {
    const element = document.getElementById(
      `deal${indexDeal}`
    ) as HTMLInputElement;
    element.classList.toggle('collapsed');
    this.collapsed = element.classList.contains('collapsed');
  }

  getFinancialProduct(financialProductType: FinancialProductEnum) {
    if (financialProductType == FinancialProductEnum.directCredit) {
      return 'Crédito directo';
    } else if (financialProductType == FinancialProductEnum.financialLeasing) {
      return 'Arrendamiento financiero';
    } else if (financialProductType == FinancialProductEnum.pureLease) {
      return 'Arrendamiento puro';
    } else {
      return '';
    }
  }

  showInsuranceVehicle(deal: any) {
    return Util.showInsuranceVehicle(deal);
  }
}
