<div class="module">
  <div class="tabs w2">
    <a (click)="selectSection(1)" [ngClass]="{ active: showSection == 1 }"
      >Amortizaciones</a
    >
    <a (click)="selectSection(2)" [ngClass]="{ active: showSection == 2 }"
      >Períodos de gracia y pagos especiales</a
    >
  </div>
  <div>
    <app-payments-standard-quote
      *ngIf="showSection == 1"
      [payments]="payments"
      [specialPayments]="specialPayments"
      [grace]="grace"
      [idQuote]="id"
      [idDeal]="idDeal"
      [isEdit]="true"
    ></app-payments-standard-quote>
    <app-list-special-payments-grace-period
      *ngIf="showSection == 2"
      [specialPayments]="specialPayments"
      [grace]="grace"
      [idQuote]="id"
      [idDeal]="idDeal"
      [payments]="payments"
      [isEdit]="true"
    >
    </app-list-special-payments-grace-period>
  </div>
</div>
