<app-preloader *ngIf="isLoading"></app-preloader>
<app-notifications></app-notifications>
<app-img-header
  title="Cotizador estándar"
  hasSubTitle="true"
  [subTitle]="
    id
      ? 'Editar propuesta para la cotización ' + dataService?.quoteName
      : 'Nueva propuesta para la cotización ' + dataService?.quoteName
  "
  alt="Daimler Truck"
  image="/assets/images/cotizador/intro.jpg"
>
</app-img-header>
<main class="main" id="standardQuotation">
  <div id="detail" class="white">
    <div class="pageWidth">
      <form [formGroup]="form">
        <!-- Crear componente para vista de Servicio Financiero -->
        <app-financial-service-view></app-financial-service-view>

        <app-customer-view></app-customer-view>

        <!-- Crear componente para vista de Unidad -->
        <app-models-view></app-models-view>
        <!-- <app-accessories-list></app-accessories-list> -->

        <app-financial-product
          formControlName="financialSetup"
          (financialConditionEmit)="setCondition($event)"
          [financialCondition]="form.controls['financialCondition'].value"
          (_deleteAllPayments)="deleteAllPayments()"
          [grace]="form.controls['grace'].value"
          [specialPayments]="form.controls['specialPayments'].value"
          [insurance]="form.controls['insurance'].value"
          (requestDownloadJson)="handleDownloadJson()"
        ></app-financial-product>

        <div class="actions">
          <button
            (click)="calculate()"
            class="secondary"
            [disabled]="
              form.controls['financialSetup'].invalid
            "
          >
            Calcular
          </button>

          <button (click)="triggerDownloadJson()" class="secondary">
            Descargar JSON
          </button>
        </div>
        <!-- <button (click)="status()">status</button> -->
        <app-insurance
        *ngIf="canAddInsurance()"
          formControlName="insurance"
          (updateInsurance)="calculate()"
          (resultCoverage)="coverageResult = $event"
          (resetInsurance)="resetInsurance($event)"
          [idDeal]="idDeal"
          [idQuote]="id"
          [financialSetup]="form.controls['financialSetup'].value"
          [financialCondition]="form.controls['financialCondition'].value"
          [isValid]="this.form.controls['financialSetup'].valid"
          [postalCode]="postalCode"
        ></app-insurance>
        <ng-container *ngIf="!canAddInsurance()">
          El seguro no está habilitado para esta combinación.
        </ng-container>


        <app-result
          [financialProduct]="form.controls['financialSetup'].value?.financialProduct"
          [quoteResult]="form.controls['result'].value"
          [coverageResult]="form.controls['insurance'].value"
        ></app-result>
        <div class="textareaWrapper">
          <label for="comentario">Comentario</label>
          <textarea name="comentario" formControlName="comment"></textarea>
        </div>
        <div class="module">
          <div class="tabs w2">
            <a
              (click)="selectSection(1)"
              [ngClass]="{ active: showSection == 1 }"
              >Amortizaciones</a
            >
            <!-- <a
              (click)="selectSection(2)"
              [ngClass]="{ active: showSection == 2 }"
              >Períodos de gracia y pagos especiales</a
            > -->
          </div>
          <div>
            <app-payments-standard-quote
              *ngIf="showSection == 1"
              [payments]="form.controls['payments'].value"
              [specialPayments]="form.controls['specialPayments'].value"
              [grace]="form.controls['grace'].value"
              [idQuote]="id"
              [idDeal]="idDeal"
              [result]="form.controls['result'].value"
              (updateSpecialPayments)="updateSpecialPayments($event)"
              (updateGrace)="updateGrace($event)"
            ></app-payments-standard-quote>
            <!-- <app-list-special-payments-grace-period
              *ngIf="showSection == 2"
              [specialPayments]="form.controls['specialPayments'].value"
              [grace]="form.controls['grace'].value"
              [idQuote]="id"
              [idDeal]="idDeal"
              [payments]="form.controls['payments'].value"
              [result]="form.controls['result'].value"

              (updateSpecialPayments)="updateSpecialPayments($event)"
              (updateGrace)="updateGrace($event)"
            >
            </app-list-special-payments-grace-period> -->
          </div>
        </div>
      </form>
      <div class="quotationActions">
        <button type="button" class="secondary" (click)="cancel()">
          Volver
        </button>
        <button
          type="button"
          class="primary"
          (click)="save(true)"
          [disabled]="this.form.invalid"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</main>
